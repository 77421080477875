/* eslint-disable no-underscore-dangle */
import {
  CHAT_GET_CONTACTS,
  CHAT_GET_CONTACTS_SUCCESS,
  CHAT_GET_CONTACTS_ERROR,
  CHAT_GET_CONVERSATIONS,
  CHAT_GET_CONVERSATIONS_SUCCESS,
  CHAT_GET_CONVERSATIONS_ERROR,
  CHAT_ADD_MESSAGE_TO_CONVERSATION,
  CHAT_CREATE_CONVERSATION,
  CHAT_SEARCH_CONTACT,
  CHAT_CHANGE_CONVERSATION,
} from '../actions';

// import contactsData from '../../data/chat.contacts.json';
// import conversationsData from '../../data/chat.conversations.json';

const INIT_STATE = {
  allContacts: null,
  contacts: null,
  conversations: null,
  error: '',
  searchKeyword: '',
  loadingContacts: false,
  loadingConversations: false,
  selectedUser: null,
  selectedUserId: null,
};

export default (state = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case CHAT_GET_CONTACTS:
      return { ...state, loadingContacts: false };

    case CHAT_GET_CONTACTS_SUCCESS:
      return {
        ...state,
        loadingContacts: true,
        allContacts: payload,
        contacts: payload,
      };

    case CHAT_GET_CONTACTS_ERROR:
      return { ...state, loadingContacts: false, error: action.payload };

    case CHAT_GET_CONVERSATIONS:
      return { ...state, loadingConversations: false };

    case CHAT_GET_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        loadingConversations: true,
        conversations: payload.conversations,
        selectedUserId: payload.selectedUserId,
      };

    case CHAT_GET_CONVERSATIONS_ERROR:
      return { ...state, loadingConversations: false, error: action.payload };

    case CHAT_CHANGE_CONVERSATION:
      return {
        ...state,
        selectedUser: state.allContacts.find((x) => x._id === payload),
      };

    case CHAT_ADD_MESSAGE_TO_CONVERSATION:
      return {
        ...state,
        conversations: state.conversations.map((conversation) =>
          conversation._id === payload._id ? payload : conversation
        ),
      };
    case CHAT_CREATE_CONVERSATION:
      return {
        ...state,
        loadingConversations: true,
        conversations: [
          ...state.conversations,
          {
            id: state.conversations.length + 1,
            users: [payload.currentUserId, payload.selectedUserId],
            lastMessageTime: '-',
            messages: [],
          },
        ],
      };

    case CHAT_SEARCH_CONTACT:
      if (action.payload === '') {
        return { ...state, contacts: state.allContacts };
      }
      // eslint-disable-next-line no-case-declarations
      const keyword = action.payload.toLowerCase();
      // eslint-disable-next-line no-case-declarations
      const searchedContacts = state.allContacts.filter(
        (item) => item.name.toLowerCase().indexOf(keyword) > -1
      );
      return { ...state, contacts: searchedContacts };

    default:
      return { ...state };
  }
};
