import {
  CLEAR_FILTER,
  GET_REVIEWER,
  USER_UPDATE,
  USER_DELETE,
  FILTER_REVIEWERS,
  REVIEWERS_ERROR,
} from '../actions';

const initialState = {
  reviewers: [],
  currentPage: 1,
  pageSize: 4,
  totalItem: 0,
  totalPage: 1,
  filtered: [],
  loading: true,
  error: {},
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_REVIEWER:
      return {
        ...state,
        reviewers: payload.data,
        currentPage: payload.currentPage,
        pageSize: payload.pageSize,
        totalItem: payload.totalItem,
        totalPage: payload.totalPage,
        loading: false,
      };

    case FILTER_REVIEWERS:
      return {
        ...state,
        filtered: state.reviewers.filter((reviewer) => {
          const regex = new RegExp(`${action.payload}`, 'gi');
          console.log(regex, reviewer);
          return (
            reviewer.name.match(regex) ||
            reviewer.rolls.match(regex) ||
            reviewer.email.match(regex) ||
            reviewer.country.match(regex) ||
            reviewer.theHub.match(regex)
          );
        }),
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filtered: null,
      };

    case USER_UPDATE:
      return {
        ...state,
        reviewers: state.reviewers.map((reviewer) =>
          // eslint-disable-next-line no-underscore-dangle
          reviewer._id === action.payload._id ? action.payload : reviewer
        ),
        loading: false,
      };

    case USER_DELETE:
      return {
        ...state,
        reviewers: state.reviewers.filter(
          // eslint-disable-next-line no-underscore-dangle
          (reviewer) => reviewer._id !== payload
        ),
      };
    case REVIEWERS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};
