// import { createStore, applyMiddleware, compose } from 'redux';
// import createSagaMiddleware from 'redux-saga';
// import thunk from 'redux-thunk';
// import reducers from './reducers';
// import sagas from './sagas';

// const sagaMiddleware = createSagaMiddleware();

// const middlewares = [thunk];

// // eslint-disable-next-line import/prefer-default-export
// export function configureStore(initialState) {
//   const store = createStore(
//     reducers,
//     initialState,
//     compose(applyMiddleware(...middlewares))
//   );

//   // sagaMiddleware.run(sagas);

//   // if (module.hot) {
//   //   module.hot.accept('./reducers', () => {
//   //     // eslint-disable-next-line global-require
//   //     const nextRootReducer = require('./reducers');
//   //     store.replaceReducer(nextRootReducer);
//   //   });
//   }

// }
// export default store;
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootRerducer from './reducers';

const initialState = {};
const middleware = [thunk];

const store = createStore(
  rootRerducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);
export default store;
