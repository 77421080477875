/* eslint-disable no-underscore-dangle */
import {
  GET_RESEARCHS,
  GET_RESEARCH,
  RESEARCH_ERROR,
  ADD_RESEARCH,
  UPDATE_RESEARCH,
  GET_REVIEWER,
  FILTER_RESEARCH,
  CLEAR_FILTER,
  FILTERING_RESEARCH,
  CHANGE_LANG,
  USER_UPDATE,
  USER_DELETE,
  DELETE_RESEARCH,
} from '../actions';

const initialState = {
  researchs: [],
  research: null,
  currentPage: 1,
  pageSize: 4,
  totalItem: 0,
  totalPage: 1,
  filtered: [],
  lang: 'ar',
  loading: true,
  error: {},
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_RESEARCHS:
      return {
        ...state,
        researchs: payload.data,
        currentPage: payload.currentPage,
        pageSize: payload.pageSize,
        totalItem: payload.totalItem,
        totalPage: payload.totalPage,
        loading: false,
      };
    case GET_RESEARCH:
      return {
        ...state,
        // eslint-disable-next-line no-underscore-dangle
        research: state.researchs.find((research) => research._id === payload),
        loading: false,
      };
    case FILTERING_RESEARCH:
      return {
        ...state,
        filtered: state.researchs.filter((research) => {
          // const status = action.payload.status
          //   ? new RegExp(`${action.payload.status}`, "gi")
          //   : "not";
          // // const reve = new RegExp(`${action.payload.filters.Reviewer}`, "gi");
          // const pstatus = action.payload.paperStatus
          //   ? new RegExp(`${action.payload.paperStatus}`, "gi")
          //   : "not";
          return (
            research.theHub === payload.filters.thehub ||
            research.TypeOfParticipation ===
              payload.filters.TypeOfParticipation ||
            (research.status3 !== '' &&
              research.status3 === action.payload.filters.status3) ||
            (action.payload.filters.Reviewer === 'accepted' &&
              research.Reviewer &&
              research.Reviewer !== '') ||
            (action.payload.filters.Reviewer === 'no' && !research.Reviewer) ||
            (action.payload.filters.Reviewer2 === 'accepted' &&
              research.Reviewer2 &&
              research.Reviewer2 !== '') ||
            (action.payload.filters.Reviewer2 === 'no' && !research.Reviewer2)
          );
        }),
      };
    case FILTER_RESEARCH:
      return {
        ...state,
        filtered: state.researchs.filter((research) => {
          const regex = new RegExp(`${action.payload}`, 'gi');
          return (
            research.researchName.match(regex) ||
            research.theHub.match(regex) ||
            research.author.match(regex) ||
            research.country.match(regex)
          );
        }),
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filtered: null,
      };
    case ADD_RESEARCH:
      return {
        ...state,
        researchs: [payload, ...state.researchs],
        loading: false,
      };
    case CHANGE_LANG:
      return {
        ...state,
        lang: payload,
      };
    case UPDATE_RESEARCH:
      return {
        ...state,
        researchs: state.researchs.map((research) =>
          research._id === payload._id ? payload : research
        ),
        research: payload,
        loading: false,
      };
    case USER_UPDATE:
      return {
        ...state,
        reviewers: state.reviewers.map((reviewer) =>
          // eslint-disable-next-line no-underscore-dangle
          reviewer._id === action.payload._id ? action.payload : reviewer
        ),
        loading: false,
      };
    case GET_REVIEWER:
      return {
        ...state,
        reviewers: payload,
      };
    case USER_DELETE:
      return {
        ...state,
        reviewers: state.reviewers.filter(
          // eslint-disable-next-line no-underscore-dangle
          (reviewer) => reviewer._id !== payload
        ),
      };
    case DELETE_RESEARCH:
      return {
        ...state,
        researchs: state.researchs.filter(
          // eslint-disable-next-line no-underscore-dangle
          (research) => research._id !== payload
        ),
      };
    case RESEARCH_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};
