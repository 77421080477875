import Axios from 'axios';
// eslint-disable-next-line import/no-cycle
import {
  CHAT_GET_CONTACTS,
  CHAT_GET_CONTACTS_SUCCESS,
  CHAT_GET_CONTACTS_ERROR,
  CHAT_GET_CONVERSATIONS,
  CHAT_GET_CONVERSATIONS_SUCCESS,
  CHAT_GET_CONVERSATIONS_ERROR,
  CHAT_ADD_MESSAGE_TO_CONVERSATION,
  CHAT_CREATE_CONVERSATION,
  CHAT_SEARCH_CONTACT,
  CHAT_CHANGE_CONVERSATION,
} from '../actions';

import contactsData from '../../data/chat.contacts.json';

export const getContacts = () => (dispatch) => {
  const contacts = contactsData.data;
  const currentUser = contacts[0];
  try {
    dispatch({
      type: CHAT_GET_CONTACTS,
      payload: { contacts, currentUser },
    });
  } catch (error) {
    dispatch({
      type: CHAT_GET_CONTACTS_ERROR,
      payload: error,
    });
  }
};
export const getContactsSuccess = () => async (dispatch) => {
  try {
    const { data } = await Axios.get('/api/chat/contacts');
    dispatch({
      type: CHAT_GET_CONTACTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CHAT_GET_CONTACTS_ERROR,
      payload: error,
    });
  }
};

export const getContactsError = (error) => ({
  type: CHAT_GET_CONTACTS_ERROR,
  payload: error,
});

export const getConversations = (userId) => ({
  type: CHAT_GET_CONVERSATIONS,
  payload: userId,
});
export const getConversationsSuccess = (userId) => async (dispatch) => {
  const { data } = await Axios.get(`/api/chat/getconversations/${userId}`);
  console.log(data);
  try {
    dispatch({
      type: CHAT_GET_CONVERSATIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CHAT_GET_CONVERSATIONS_ERROR,
      payload: error,
    });
  }
};

export const createConversation = (
  currentUserId,
  selectedUserId,
  allConversations
) => (dispatch) => {
  console.log(currentUserId, selectedUserId, allConversations);
  try {
    dispatch({
      type: CHAT_CREATE_CONVERSATION,
      payload: { currentUserId, selectedUserId, allConversations },
    });
  } catch (error) {
    dispatch({
      type: CHAT_GET_CONVERSATIONS_ERROR,
      payload: error,
    });
  }
};

export const getConversationsError = (error) => ({
  type: CHAT_GET_CONVERSATIONS_ERROR,
  payload: error,
});

export const addMessageToConversation = (
  currentUserId,
  selectedUserId,
  message
) => async (dispatch) => {
  try {
    const { data } = await Axios.post('/api/chat/conversations', {
      currentUserId,
      selectedUserId,
      message,
    });
    dispatch({
      type: CHAT_ADD_MESSAGE_TO_CONVERSATION,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CHAT_GET_CONVERSATIONS_ERROR,
      payload: error,
    });
  }
};

export const searchContact = (keyword) => ({
  type: CHAT_SEARCH_CONTACT,
  payload: keyword,
});

export const changeConversation = (userId) => (dispatch) => {
  dispatch({
    type: CHAT_CHANGE_CONVERSATION,
    payload: userId,
  });
};
