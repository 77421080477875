/* eslint-disable import/no-cycle */
/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const CURRENTUSER_UPDATE = 'CURRENTUSER_UPDATE';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* TODOAPP */
export const TODO_GET_LIST = 'TODO_GET_LIST';
export const TODO_GET_LIST_SUCCESS = 'TODO_GET_LIST_SUCCESS';
export const TODO_GET_LIST_ERROR = 'TODO_GET_LIST_ERROR';
export const TODO_GET_LIST_WITH_FILTER = 'TODO_GET_LIST_WITH_FILTER';
export const TODO_GET_LIST_WITH_ORDER = 'TODO_GET_LIST_WITH_ORDER';
export const TODO_GET_LIST_SEARCH = 'TODO_GET_LIST_SEARCH';
export const TODO_ADD_ITEM = 'TODO_ADD_ITEM';
export const TODO_ADD_ITEM_SUCCESS = 'TODO_ADD_ITEM_SUCCESS';
export const TODO_ADD_ITEM_ERROR = 'TODO_ADD_ITEM_ERROR';
export const TODO_SELECTED_ITEMS_CHANGE = 'TODO_SELECTED_ITEMS_CHANGE';

/* CHAT APP */
export const CHAT_GET_CONTACTS = 'CHAT_GET_CONTACTS';
export const CHAT_GET_CONTACTS_SUCCESS = 'CHAT_GET_CONTACTS_SUCCESS';
export const CHAT_GET_CONTACTS_ERROR = 'CHAT_GET_CONTACTS_ERROR';
export const CHAT_GET_CONVERSATIONS = 'CHAT_GET_CONVERSATIONS';
export const CHAT_GET_CONVERSATIONS_SUCCESS = 'CHAT_GET_CONVERSATIONS_SUCCESS';
export const CHAT_GET_CONVERSATIONS_ERROR = 'CHAT_GET_CONVERSATIONS_ERROR';
export const CHAT_ADD_MESSAGE_TO_CONVERSATION =
  'CHAT_ADD_MESSAGE_TO_CONVERSATION';
export const CHAT_CREATE_CONVERSATION = 'CHAT_CREATE_CONVERSATION';
export const CHAT_SEARCH_CONTACT = 'CHAT_SEARCH_CONTACT';
export const CHAT_CHANGE_CONVERSATION = 'CHAT_CHANGE_CONVERSATION';

/* SURVEY LIST APP */
export const SURVEY_LIST_GET_LIST = 'SURVEY_LIST_GET_LIST';
export const SURVEY_LIST_GET_LIST_SUCCESS = 'SURVEY_LIST_GET_LIST_SUCCESS';
export const SURVEY_LIST_GET_LIST_ERROR = 'SURVEY_LIST_GET_LIST_ERROR';
export const SURVEY_LIST_GET_LIST_WITH_FILTER =
  'SURVEY_LIST_GET_LIST_WITH_FILTER';
export const SURVEY_LIST_GET_LIST_WITH_ORDER =
  'SURVEY_LIST_GET_LIST_WITH_ORDER';
export const SURVEY_LIST_GET_LIST_SEARCH = 'SURVEY_LIST_GET_LIST_SEARCH';
export const SURVEY_LIST_ADD_ITEM = 'SURVEY_LIST_ADD_ITEM';
export const SURVEY_LIST_ADD_ITEM_SUCCESS = 'SURVEY_LIST_ADD_ITEM_SUCCESS';
export const SURVEY_LIST_ADD_ITEM_ERROR = 'SURVEY_LIST_ADD_ITEM_ERROR';
export const SURVEY_LIST_SELECTED_ITEMS_CHANGE =
  'SURVEY_LIST_SELECTED_ITEMS_CHANGE';

/* SURVEY DETAIL APP */
export const SURVEY_GET_DETAILS = 'SURVEY_GET_DETAILS';
export const SURVEY_GET_DETAILS_SUCCESS = 'SURVEY_GET_DETAILS_SUCCESS';
export const SURVEY_GET_DETAILS_ERROR = 'SURVEY_GET_DETAILS_ERROR';
export const SURVEY_DELETE_QUESTION = 'SURVEY_DELETE_QUESTION';
export const SURVEY_SAVE = 'SURVEY_SAVE';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const GET_RESEARCHS = 'GET_RESEARCHS';
export const GET_RESEARCH = 'GET_RESEARCH';
export const RESEARCH_ERROR = 'RESEARCH_ERROR';
export const ADD_RESEARCH = 'ADD_RESEARCH';
export const UPDATE_RESEARCH = 'UPDATE_RESEARCH';
export const CHANGE_LANG = 'CHANGE_LANG';
export const CHANGE_STATUS = 'CHANGE_STATUS';
export const FILTER_RESEARCH = 'FILTER_RESEARCH';
export const FILTERING_RESEARCH = 'FILTERING_RESEARCH';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const DELETE_RESEARCH = 'DELETE_RESEARCH';

// REVIEWERS
export const GET_REVIEWER = 'GET_REVIEWER';
export const USER_AGREE = 'USER_AGREE';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_DELETE = 'USER_DELETE';
export const FILTER_REVIEWERS = 'FILTER_REVIEWERS';
export const REVIEWERS_ERROR = 'REVIEWERS_ERROR';
// export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
// export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
// export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
// export const LOGIN_USER = 'LOGIN_USER';
// export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
// export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
// export const LOGOUT_USER = 'LOGOUT_USER';
// export const REGISTER_USER = 'REGISTER_USER';
// export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
// export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
// export const RESET_PASSWORD = 'RESET_PASSWORD';
// export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
// export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

export * from './menu/actions';
export * from './settings/actions';
export * from './todo/actions';
export * from './chat/actions';
export * from './surveyList/actions';
export * from './surveyDetail/actions';
